.itemsCard{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.itemPhotos img{
    width: 200px;
    height: 250px;
}
.itemDis{
    padding: 10px 10px;
}
.itemAmm{
    font-weight: bold;
}