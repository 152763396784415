.auth{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.cardItem{
    text-align: center;
    padding-left: 20px;
    float: right;
}
.authSection a{
    text-decoration: none;
    color: #fff;
    padding: 0px 15px;
}
.login{
    position: relative;   
}
.login::before{
    position:absolute;
    content: '';
    background: #fff;
    width: 1px;
    height: 15px;
    right: 0px;
    bottom: 0px;
}
.bgDark{
    background-color: #131921;
    color:white;
}
.headerMain{
    display: flex;
    align-items: center;
    
}
.searchBox{
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 50px;
    display: flex;
    align-items: center;
    padding: 5px 8px;
}
.searchBox input{
    flex: 1;
    padding: 0px 10px;
    border: 0;
    background: transparent;
    color: #fff;
    font-size: 18px;
}
.searchBox button img{
    width: 25px;
}
.searchBox button{
    width: 40px;
    height: 40px;
    border: 0;
    border-radius: 50%;
    background-color: #58629b;
    cursor: pointer;
    outline: none;
}
.searchBox input:focus{
    outline: none;
}
::placeholder{
    color: rgb(158, 156, 156);
}
.reg{
    position: relative;
}
.reg::after{
    position: absolute;
    content: '';
    background: #fff;
    width: 1px;
    height: 15px;
    bottom: 0px;
    right:-2px;
}
