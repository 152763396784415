.footerMain{
    background-color: #131921;
}
.footer-logo{
    width: 170px;
    color:white;
}
p, b{
    color:white;
}

