
.sellsCat{
    padding:10px 0px ;
}
.cardImg{
    display: flex;
    border-radius: 50px;
    transition: 0.3s;
}
.card-text{
    display: flex;
    text-align: center;
    padding: 10px;
}
.cardImg:hover{
    transform: scale(1.1);
    border: 10px solid rgb(245, 177, 175);
    -webkit-box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75);
    box-shadow: 2px 3px 14px -1px rgba(0,0,0,0.75);
}
